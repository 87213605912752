<template>
  <div>
    <slot name="header" />

    <div
      class="container mx-auto pt-8 pb-12 py-8 md:py-16 max-w-xl"
      v-if="!showSuccessMessage">
      <h2 class="font-bold text-xl sm:text-3xl leading-tight text-gray-800 text-center headline mb-3 md:mb-6">
        Hva synes du om {{ $app.name }}?
      </h2>
      <p class="text-muted text-center md:px-8">
        Legg igjen en anmeldelse av din kjøpsopplevelse på {{ $app.name }} nå, så blir du med i vår ukentlige trekning av Maja-longsleeve. <a
          href="https://www.maja.no/konk-vinnere"
          target="_blank"
          class="underline">Se tidligere vinnere her</a>.
      </p>
      <div class="flex justify-between my-6 md:mx-auto md:max-w-xl">
        <div class="w-1/3 px-2">
          <img
            src="~@/assets/images/price-1.jpg"
            alt="Premie"
            class="object-cover">
        </div>
        <div class="w-1/3 px-2">
          <img
            src="~@/assets/images/price-2.jpg"
            alt="Premie"
            class="object-cover">
        </div>
        <div class="w-1/3 px-2">
          <img
            src="~@/assets/images/price-3.jpg"
            alt="Premie"
            class="object-cover">
        </div>
      </div>
      <div class="text-center mt-8 mb-6">
        <div class="inline-block">
          <star-rating
            v-model="stampedFormData.reviewRating"
            :show-rating="false"
            :star-size="35"
            :inline="true" />
        </div>
      </div>

      <div class="md:mx-auto md:max-w-xl">
        <input-field
          label="Fornavn"
          v-model="stampedFormData.author"
          class="mb-4" />
        <input-field
          label="E-post (brukes kun til å varsle vinnerne)"
          v-model="stampedFormData.email"
          class="mb-4" />
        <input-checkbox
          label="Ja takk, jeg vil ha nyhetsbrev fra Maja!"
          v-model="newsletter"
          class="mb-6 -mt-2 text-sm" />
        <input-field
          label="Tittel på anmeldelsen"
          v-model="stampedFormData.reviewTitle"
          class="mb-4" />
        <input-textarea
          label="Din anmeldelse"
          v-model="stampedFormData.reviewMessage"
          class="mb-4" />

        <div
          v-if="showValidationError"
          class="bg-red-600 text-white px-3 py-2 rounded-sm relative text-sm my-6"
          role="alert">
          Vær snill å fylle ut alle felter før du kan sende inn.
        </div>

        <div
          v-if="showFailedMessage"
          class="bg-red-600 text-white px-3 py-2 rounded-sm relative text-sm my-6"
          role="alert">
          Det oppstod en feil ved innsending.
        </div>

        <div class="flex justify-end">
          <default-button
            primary
            @click.native.prevent="submit"
            :disabled="submitting"
            :loading="submitting">
            Send inn
          </default-button>
        </div>
      </div>
    </div>
    <div class="container mx-auto pt-8 pb-12 md:py-12 max-w-xl">
      <success-message v-if="showSuccessMessage">
        Takk for tilbakemeldingen!
      </success-message>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import qs from "querystring"
import StarRating from "vue-star-rating"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },

    formTitle: {
      type: String,
      default: null
    },

    formSlug: {
      type: String,
      default: null
    },
  },

  components: {
    StarRating
  },

  data () {
    return {
      showValidationError: false,
      showSuccessMessage: false,
      showFailedMessage: false,
      submitting: false,
      stampedFormData: {
        productId: this.formData.formId,
        author: null,
        email: null,
        reviewRating: null,
        reviewTitle: null,
        reviewMessage: null,
        productName: this.formTitle,
        productSKU: this.formSlug,
        productUrl: `https://${this.$app.domain}/${this.formSlug}`,
        reviewSource: "api",
      },
      newsletter: false
    }
  },

  methods: {
    submit () {
      this.showFailedMessage = false

      if (!this.stampedRequestIsValid) {
        this.showValidationError = true
        return
      }

      if (this.newsletter === true) {
        this.subscribeToNewsletter()
      }

      this.submitting = true

      axios.post("https://stamped.io/api/reviews2?apiKey=pubkey-9ZMnaBr7CFv30Q9S6QKTM4mmH1437y&sId=119331", qs.stringify(this.stampedFormData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }).then(() => {
        this.showSuccessMessage = true
        this.submitting = false
      }).catch(() => {
        this.showFailedMessage = true
        this.submitting = false
      })
    },

    subscribeToNewsletter () {
      axios.post("/api/email-signup", { email: this.stampedFormData.email, name: this.stampedFormData.author, review: this.stampedFormData.reviewRating })
        .catch(() => {})
    }
  },

  watch: {
    stampedFormData: {
      handler: function () {
        this.showValidationError = false
      },
      deep: true
    }
  },

  computed: {
    stampedRequestIsValid () {
      let form = this.stampedFormData

      let validate = [
        form.reviewRating,
        form.reviewTitle,
        form.reviewMessage,
        form.email,
        form.author
      ]

      return validate.filter(value => !value).length <= 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-muted {
    color: var(--text-muted-color);
  }
</style>
